import { Grid, Typography } from '@material-ui/core'
import { graphql, PageProps } from 'gatsby'
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image'
import React, { useEffect } from 'react'
import Layout from '../components/layout'
import notFalsyPredicate from '../utils/notFalsyPredicate'

type HistoryProps = { data: GatsbyTypes.HistoryQuery } & PageProps

const History = ({
  location,
  data: {
    allFile: { nodes: historyFiles },
    file,
  },
}: HistoryProps) => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (window.SDG) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.SDG.Publisher.setZone('content')
    }
  }, [])
  const metaImageData =
    file &&
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    getImage(file)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { height, width } = metaImageData

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const imagePath = getSrc(file)
  return (
    <Layout
      location={location}
      seoProps={{
        title: 'Verlag',
        description: 'TODO',
        images: [
          {
            alt: 'Karl Baedeker',
            caption: '',
            height,
            width,
            path: imagePath ?? '',
          },
        ],
      }}
    >
      {historyFiles
        .map(({ id, childMarkdownRemark }) => {
          if (!childMarkdownRemark) {
            return null
          }

          const { html, frontmatter } = childMarkdownRemark

          if (!html || !frontmatter) {
            return null
          }

          const { title, image } = frontmatter

          if (!title) {
            return null
          }

          const gatsbyImageData =
            image &&
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            getImage(image)

          return (
            <div key={id} style={{ padding: 16 }}>
              <Grid component="section" container spacing={4} justify="center">
                {gatsbyImageData && (
                  <Grid item xs={12}>
                    <GatsbyImage image={gatsbyImageData} alt="" />
                  </Grid>
                )}

                <Grid item xs={12} lg={8}>
                  <Typography variant="h2" gutterBottom>
                    {title}
                  </Typography>

                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: html }} />
                </Grid>
              </Grid>
            </div>
          )
        })
        .filter(notFalsyPredicate)}
    </Layout>
  )
}
export default History

export const historyQuery = graphql`
  query History {
    file(relativePath: { eq: "images/content/home/history.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    allFile(
      filter: { relativeDirectory: { eq: "geschichte" } }
      sort: { fields: [childMarkdownRemark___frontmatter___date], order: DESC }
    ) {
      nodes {
        id
        childMarkdownRemark {
          id
          html
          frontmatter {
            title
            image {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`
